import { createGetKcContext } from "keycloakify/login";

export type KcContextExtension =
	| { pageId: "login.ftl";  }
	| { pageId: "info.ftl";  }
	| { pageId: "login-password.ftl";  }
	| { pageId: "login-reset-password.ftl";  }
	| { pageId: "login-update-password.ftl";  }
	| { pageId: "my-extra-page-1.ftl"; }
	| { pageId: "my-extra-page-2.ftl"; someCustomValue: string; }
	// NOTE: register.ftl is deprecated in favor of register-user-profile.ftl
	// but let's say we use it anyway and have this plugin enabled: https://github.com/micedre/keycloak-mail-whitelisting
	// keycloak-mail-whitelisting define the non standard ftl global authorizedMailDomains, we declare it here.
	| { pageId: "register.ftl"; authorizedMailDomains: string[]; };

//NOTE: In most of the cases you do not need to overload the KcContext, you can 
// just call createGetKcContext(...) without type arguments.  
// You want to overload the KcContext only if:  
// - You have custom plugins that add some values to the context (like https://github.com/micedre/keycloak-mail-whitelisting that adds authorizedMailDomains)
// - You want to add support for extra pages that are not yey featured by default, see: https://docs.keycloakify.dev/contributing#adding-support-for-a-new-page
export const { getKcContext } = createGetKcContext<KcContextExtension>({
	mockData: [
		{
			pageId: "login.ftl",
			locale: {
				//When we test the login page we do it in french
				currentLanguageTag: "it",
			},
			themeName: "fieracremona",
			//Uncomment the following line for hiding the Alert message
			//"message": undefined
			//Uncomment the following line for showing an Error message
			//message: { type: "error", summary: "This is an error" }
		},
		{
			pageId: "info.ftl",
			themeName: "fieracremona",
			locale: {
				//When we test the login page we do it in french
				currentLanguageTag: "it",
			},
			"auth": {
				"showResetCredentials": false,
				"showUsername": false,
				"showTryAnotherWayLink": false,
				/*If you need 'attemptedUsername' on info.ftl, please submit an issue to the Keycloakify repo*/
			},
			"actionUri": "https://accounts.cremona.dticketing.com/realms/platform-stag/login-actions/action-token?key=eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJhOWZlNDkyMi02NDFjLTQ4NmEtODhjZi05ZTU1NGM1NGE0ZDcifQ.eyJleHAiOjE3MjIzNzE0MTksImlhdCI6MTcyMjMyODMwOSwianRpIjoiOThiMzRjYmYtMTQzNi00YjhjLWEzMWEtZDMzMWU0MGQ0NmRlIiwiaXNzIjoiaHR0cHM6Ly9hY2NvdW50cy5jcmVtb25hLmR0aWNrZXRpbmcuY29tL3JlYWxtcy9wbGF0Zm9ybS1zdGFnIiwiYXVkIjoiaHR0cHM6Ly9hY2NvdW50cy5jcmVtb25hLmR0aWNrZXRpbmcuY29tL3JlYWxtcy9wbGF0Zm9ybS1zdGFnIiwic3ViIjoiMjA4N2Y3Y2UtZWRjZS00NjFiLWI2NWYtMmVmNWZkNjAxZmY5IiwidHlwIjoiZXhlY3V0ZS1hY3Rpb25zIiwiYXpwIjoiZGVzayIsIm5vbmNlIjoiOThiMzRjYmYtMTQzNi00YjhjLWEzMWEtZDMzMWU0MGQ0NmRlIiwiZW1sIjoibWF0dGlhLm1lbmVnaGV0dGlAbXlkb21uaWEuY29tIiwicnFhYyI6WyJVUERBVEVfUEFTU1dPUkQiXSwiYXNpZCI6IjQ3Njc3OWVlLTY1M2UtNGU2ZC05NGRhLWRjMzAwNzdlYTQ2Yi53RlQzTUZGZUpPVS4wZjY0Nzk3Mi02MTcyLTRlZmEtYTgyOS02MWJjZTAwMDkyODAiLCJhc2lkIjoiNDc2Nzc5ZWUtNjUzZS00ZTZkLTk0ZGEtZGMzMDA3N2VhNDZiLndGVDNNRkZlSk9VLjBmNjQ3OTcyLTYxNzItNGVmYS1hODI5LTYxYmNlMDAwOTI4MCIsInJxYWMiOlsiVVBEQVRFX1BBU1NXT1JEIl19.gYkFYGs6c_Ju7XEflp4ZZZxMuTYwPMyFRDbziEq_4ak&client_id=desk&tab_id=wFT3MFFeJOU",
			"message": {
				"summary": "Perform the following action(s)",
				"type": "success",
			},
			"url": {
				"resourcesPath": "\/resources/a9bmi/login/fieracremona",
				/*If you need 'url.loginAction' on info.ftl, please submit an issue to the Keycloakify repo*/
				"loginUrl": "\/realms/platform-stag/login-actions/authenticate?client_id=desk&tab_id=wFT3MFFeJOU",
				"resourcesCommonPath": "\/resources/a9bmi/common/keycloak",
				/*If you need 'url.loginUpdateProfileUrl' on info.ftl, please submit an issue to the Keycloakify repo*/
				/*If you need 'url.loginUsernameReminderUrl' on info.ftl, please submit an issue to the Keycloakify repo*/
				"loginRestartFlowUrl": "\/realms/platform-stag/login-actions/restart?client_id=desk&tab_id=wFT3MFFeJOU",
			},
			"requiredActions": [
				"UPDATE_PASSWORD",
			],
			"messagesPerField": {
			}
		},
		{
			pageId: "login-password.ftl",
			locale: {
				currentLanguageTag: "it",
			},
		},
		{
			pageId: "login-reset-password.ftl",
			locale: {
				currentLanguageTag: "it",
			},
		},
		{
			pageId: "login-update-password.ftl",
			locale: {
				currentLanguageTag: "it",
			},
		},
		// {
		// 	pageId: "my-extra-page-2.ftl",
		// 	someCustomValue: "foo bar baz",
		// },
		// {
		// 	//NOTE: You will either use register.ftl (legacy) or register-user-profile.ftl, not both
		// 	pageId: "register-user-profile.ftl",
		// 	locale: {
		// 		currentLanguageTag: "it"
		// 	},
		// 	profile: {
		// 		attributes: [
		// 			{
		// 				validators: {
		// 					pattern: {
		// 						pattern: "^[a-zA-Z0-9]+$",
		// 						"ignore.empty.value": true,
		// 						// eslint-disable-next-line no-template-curly-in-string
		// 						"error-message": "${alphanumericalCharsOnly}",
		// 					},
		// 				},
		// 				//NOTE: To override the default mock value
		// 				value: undefined,
		// 				name: "username"
		// 			},
		// 			{
		// 				validators: {
		// 					options: {
		// 						options: ["male", "female", "non-binary", "transgender", "intersex", "non_communicated"]
		// 					}
		// 				},
		// 				// eslint-disable-next-line no-template-curly-in-string
		// 				displayName: "${gender}",
		// 				annotations: {},
		// 				required: true,
		// 				groupAnnotations: {},
		// 				readOnly: false,
		// 				name: "gender"
		// 			}
		// 		]
		// 	}
		// },
		// {
		// 	pageId: "register.ftl",
		// 	authorizedMailDomains: [
		// 		"example.com",
		// 		"another-example.com",
		// 		"*.yet-another-example.com",
		// 		"*.example.com",
		// 		"hello-world.com"
		// 	],
		// 	// Simulate we got an error with the email field. Return text if message for given field exists.
		// 	messagesPerField: {
		// 		printIfExists: <T>(fieldName: string, text: T) => { console.log({ fieldName }); return fieldName === "email" ? text : undefined; },
		// 		existsError: (fieldName: string) => fieldName === "email",
		// 		get: (fieldName: string) => `Fake error for ${fieldName}`,
		// 		exists: (fieldName: string) => fieldName === "email"
		// 	},

		// }
	],
	// Defined in vite.config.ts
	// See: https://docs.keycloakify.dev/environnement-variables
	// mockProperties: {
	// 	MY_ENV_VARIABLE: "Mocked value"
	// }
});

export const { kcContext } = getKcContext({
	// Uncomment to test the login page for development.
	// mockPageId: "login.ftl",
	mockPageId: "info.ftl",
	// mockPageId: "login-password.ftl",
	// mockPageId: "login-reset-password.ftl",
	// mockPageId: "login-update-password.ftl",
});


export type KcContext = NonNullable<ReturnType<typeof getKcContext>["kcContext"]>;
